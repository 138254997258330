import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const resources = () => {
  return (
    <Layout>
      <SEO
        title="Resources"
        description="A collection of resources I used to build my PES myClub guide."
      />
      <div className="container">
        <h1>Resources</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Resources</li>
        </ul>
        <p>
          A list of sites that have made things a lot easier for the PES
          community. I mention them on some specific sections on the site, but
          here is the complete list.
        </p>
        <ul>
          <li>
            <a
              href="https://www.reddit.com/r/wepes"
              target="_blank"
              rel="noopener noreferrer"
            >
              /r/WEPES
            </a>{" "}
            <small>Reddit community</small>
          </li>
          <p>
            The Reddit community is super helpful and this is a subreddit I
            visit every day. I learned a lot here when I was a newcomer to
            myClub, and to this day I still do.
          </p>
          <p>
            You can share goals, discuss gameplay tactics, and learn about
            pretty much anything related to the game.
          </p>
          <p>
            This is usually where I read Konami's latest announcements as well.
          </p>
          <li>
            <a
              href="http://pesdb.net/pes2021/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PES Database 2021
            </a>{" "}
            <small>Game database</small>
          </li>
          <p>
            The most popular resource to check player ratings and preview their
            stats according to their training level (simply adjust the slider).
          </p>
          <p>
            This is also the place to go if you want a player and need to know
            which scouts you must have to sign him with a certainty of 100%.
          </p>
          <p>
            You can also do some advanced searches in the database with custom
            filters, to retrieve the list of players that have a certain Playing
            Style and Position, for example.
          </p>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1lSj0B0grwqohhia4XaB97xfknZ6zKEzuh1r54427FEs/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ultimate myClub Companion Spreadsheet (PES 2021)
            </a>{" "}
            <small>
              made by{" "}
              <a
                href="https://www.reddit.com/user/TheDIsSilentHilbilly"
                target="_blank"
                rel="noopener noreferrer"
              >
                /u/TheDIsSilentHilbilly
              </a>
            </small>
          </li>
          <p>
            <a
              href="https://www.reddit.com/user/TheDIsSilentHilbilly"
              target="_blank"
              rel="noopener noreferrer"
            >
              /u/TheDIsSilentHilbilly
            </a>{" "}
            has done a lot for the PES community over the years and this is the
            most recent Spreadsheet you can use. It's the Ultimate Companion
            because it is actually 3 in 1!
          </p>
          <p>
            It includes not only the "Free Scout Sheet" (which you can use to
            see if you can get any players with 100% Free Scout Combinations)
            but also an "Experience + Contract Calculator" and a "Trainer
            Experience Table", to calculate the EXP you get for each player you
            convert into a trainer.
          </p>
          <p>
            This is a super handy Google Spreadsheet, and all you need to do to
            use it is to click on "File > Make a Copy".
          </p>
          <li>
            <a
              href="https://app.powerbi.com/view?r=eyJrIjoiNjI3MGY4MzEtN2M0Mi00NzlhLWI4MzItYTA1NWE5ODA0MjhkIiwidCI6ImQyMTgzNDMxLWZjZDktNGYwNC1iODJmLTU5Njc2ZTFmMmIzOSJ9"
              target="_blank"
              rel="noopener noreferrer"
            >
              All Free 100% Combo (PES 2019)
            </a>{" "}
            <small>
              made by{" "}
              <a
                href="https://www.reddit.com/user/seerpico"
                target="_blank"
                rel="noopener noreferrer"
              >
                /u/seerpico
              </a>
            </small>
          </li>
          <p>
            This user created an app that allows you to insert the Free Scouts
            you already own, to see if you can get any player for free.
          </p>
          <p>Similar to the one above, but for PES 2019 (Data Pack 5.0).</p>
          <li>
            <a
              href="https://www.youtube.com/channel/UCvDPOmJS4aLG05TvpaFXNvA"
              target="_blank"
              rel="noopener noreferrer"
            >
              Spoony Pizzas YouTube Channel
            </a>{" "}
            <small>Gameplay Tips</small>
          </li>
          <p>
            Spoony is my favorite PES YouTuber. If you're looking for tips on
            how to improve your gameplay, have a look at Spoony's tutorials.
          </p>
          <p>
            He covers everything, from dribbling, passing or shooting, to more
            tactical aspects such as formations or advanced instructions.
          </p>
          <p>
            He plays mostly offline, so he often streams his Master League games
            as well.
          </p>
        </ul>
      </div>
    </Layout>
  )
}

export default resources
